import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// sections for this page
import Images from "../index-sections/Images.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
const HomePage = (props) => {
  const classes = useStyles();
  React.useEffect(() => {}, []);

  return (
    <>
      {/* <CircularProgress color="info" /> */}
      <Images />
    </>
  );
};

export default HomePage;
